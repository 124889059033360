import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Collage, CollageType } from "../components/collages/Collage";

const Wrapper = styled.div``;

const IndexPage = () => {
  return (
    <Wrapper>
      <Layout>
        <SEO title="Illustration" />
        <Collage type={CollageType.Illustration} />
      </Layout>
    </Wrapper>
  );
};

export default IndexPage;
